import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type {
  HomeroomListing,
  WritingTaskListing,
} from "@/application/domain/HomeroomListing.ts";
import { Checkbox } from "@/application/ui/lib/checkbox/Checkbox.tsx";
import { Page } from "@/application/ui/lib/Page.tsx";
import { LoadingPage } from "@/application/ui/lib/spinner/LoadingPage.tsx";
import { TimeoutPage } from "@/application/ui/lib/Timeout/TimeoutPage.tsx";
import type { PaneCreationlRef } from "@/application/ui/pages/Dashboard/components/WritingTaskCreationPanel.tsx";
import { WritingTaskCreationPanel } from "@/application/ui/pages/Dashboard/components/WritingTaskCreationPanel.tsx";
import type { PanelUpdateRef } from "@/application/ui/pages/Dashboard/components/WritingTaskUpdatePanel.tsx";
import { WritingTaskUpdatePanel } from "@/application/ui/pages/Dashboard/components/WritingTaskUpdatePanel.tsx";
import { theme } from "@/application/ui/theme/theme.ts";
import { ReactComponent as EditIcon } from "@/assets/edit-square.svg";
import { ReactComponent as FolderIcon } from "@/assets/folder.svg";

import { Dropdown } from "../../lib/inputs/Dropdown.tsx";
import { useFlags } from "../App/FlagsProvider.tsx";
import { TeacherDashboardContextMenu } from "./components/CreationContextMenu.tsx";
import type { PanelDraftUpdateRef } from "./components/DraftWritingTaskUpdatePanel.tsx";
import { DraftWritingTaskUpdatePanel } from "./components/DraftWritingTaskUpdatePanel.tsx";
import { TeacherDashboardSections } from "./components/TeacherDashboardSections.tsx";
import type { WritingTaskSummaryPanelRef } from "./components/WritingTaskSummaryPanel/WritingTaskSummaryPanel.tsx";
import { WritingTaskSummaryPanel } from "./components/WritingTaskSummaryPanel/WritingTaskSummaryPanel.tsx";
import { useTeacherHomerooms } from "./hooks/useTeacherHomerooms.ts";

export const REVISION_PAGE_URL = "/professeur/revision";

export const TeacherDashboard = () => {
  const { t } = useTranslation();
  const key = "homeroomId";
  const taskCreationPanelRef = useRef<PaneCreationlRef>(null);
  const taskUpdatePanelRef = useRef<PanelUpdateRef>(null);
  const taskUpdateDraftPanelRef = useRef<PanelDraftUpdateRef>(null);
  const gradeSummaryPanelRef = useRef<WritingTaskSummaryPanelRef>(null);
  const { draftWritingTask } = useFlags();
  const persistedHomeroomId = sessionStorage.getItem(key) ?? "";
  const setPersistedHomeroomId = (textContent: string) => {
    sessionStorage.setItem(key, textContent);
  };
  const { homerooms, isError: isErrorHomerooms } = useTeacherHomerooms();

  const [selectedHomeroomId, setSelectedHomeroomId] =
    useState<string>(persistedHomeroomId);
  const [includeMasked, setIncludeMasked] = useState<boolean>(false);
  const openCreationFor = (homeroomId: string, sectionId?: string) =>
    taskCreationPanelRef.current?.openFormForHomeroom(homeroomId, sectionId);

  const openUpdateFor = (homeroom: HomeroomListing, writingTaskId: string) =>
    taskUpdatePanelRef.current?.openFormForHomeroomWithWritingTask(
      homeroom,
      writingTaskId,
    );

  const openUpdateForDraft = (
    homeroom: HomeroomListing,
    writingTaskId: string,
    sectionId?: string,
  ) =>
    taskUpdateDraftPanelRef.current?.openForDraftUpdate(
      homeroom,
      writingTaskId,
      sectionId,
    );

  const onOpenGradePanel = (task: WritingTaskListing, homeroomName: string) => {
    gradeSummaryPanelRef.current?.openForWritingTask(task);
    gradeSummaryPanelRef.current?.forHomeroom(homeroomName);
  };

  const [isCreatingSection, setIsCreatingSection] = useState(false);

  const onSectionCreationCompleted = () => {
    setIsCreatingSection(false);
  };

  const { sections: sectionsFlag } = useFlags();

  const creationActions = [
    {
      label: t("newTask"),
      callback: () => openCreationFor(selectedHomeroomId),
      children: (
        <>
          <EditIcon />
          {t("newTask")}
        </>
      ),
    },
  ];

  const createSectionAction = {
    label: t("sections.newSection"),
    callback: () => setIsCreatingSection(true),
    children: (
      <>
        <FolderIcon />
        {t("sections.newSection")}
      </>
    ),
  };

  if (sectionsFlag) {
    creationActions.push(createSectionAction);
  }

  useEffect(() => {
    if (homerooms && homerooms.length && selectedHomeroomId === "") {
      setSelectedHomeroomId(homerooms[0].id);
    }
  }, [homerooms, selectedHomeroomId]);

  if (isErrorHomerooms) {
    return (
      <Page>
        <TimeoutPage />
      </Page>
    );
  }

  if (!homerooms) {
    return (
      <Page>
        <LoadingPage />
      </Page>
    );
  }

  const defaultId =
    selectedHomeroomId !== ""
      ? homerooms.filter((h) => selectedHomeroomId === h.id)[0].id
      : homerooms[0].id;

  return (
    <Page>
      <Header>
        <StyledDropdown
          options={homerooms.map((x) => ({
            label: x.name,
            value: x.id,
          }))}
          defaultValue={defaultId}
          onChange={(x) => {
            setPersistedHomeroomId(x);
            setSelectedHomeroomId(x);
          }}
          selectedItemLabelPrefix={t("HomeroomGroupsDropdown")}
          edges="round"
        />
        <RigthSideGrouping>
          <CheckboxContainer>
            <Checkbox
              checked={includeMasked}
              selectedColor={theme.colors.base.white}
              borderColor={theme.colors.primary[700]}
              backgroundSelectedColor={theme.colors.primary[700]}
              onChange={() => {
                setIncludeMasked(!includeMasked);
              }}
            />
            <span>{t("includeHiddenTask")}</span>
          </CheckboxContainer>

          <TeacherDashboardContextMenu actions={creationActions} />
        </RigthSideGrouping>
      </Header>
      {selectedHomeroomId && (
        <TeacherDashboardSections
          homeroomId={selectedHomeroomId}
          openCreationFor={openCreationFor}
          openUpdateFor={openUpdateFor}
          openUpdateForDraft={openUpdateForDraft}
          onOpenGradePanel={onOpenGradePanel}
          onSectionCreationCompleted={onSectionCreationCompleted}
          isCreatingSection={isCreatingSection}
          includeMasked={includeMasked}
        />
      )}

      <WritingTaskCreationPanel
        ref={taskCreationPanelRef}
        homerooms={homerooms}
      />
      {draftWritingTask ? (
        <DraftWritingTaskUpdatePanel ref={taskUpdateDraftPanelRef} />
      ) : (
        <WritingTaskUpdatePanel ref={taskUpdatePanelRef} />
      )}
      <WritingTaskSummaryPanel ref={gradeSummaryPanelRef} />
    </Page>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f4f7;
  border-radius: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const StyledDropdown = styled(Dropdown)`
  margin-left: 16px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${theme.typography.sm.semibold}
  align-items: center;
  gap: 8px;
`;

const RigthSideGrouping = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
