import type { MutableRefObject } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import PinInput from "react-pin-input";
import styled from "styled-components";

import { UnlockCode } from "@/application/domain/UnlockCode.ts";
import { FeaturedIcon } from "@/application/ui/lib/icon/FeaturedIcon.tsx";
import { theme } from "@/application/ui/theme/theme.ts";
import { ReactComponent as ArrowIcon } from "@/assets/arrow-right.svg";
import { ReactComponent as Lock } from "@/assets/passcode-lock.svg";

type Props = {
  writingTaskId: string;
  onClose: () => void;
};

export const SecurityModeModal = ({ writingTaskId, onClose }: Props) => {
  const [open, isOpen] = useState<boolean>(true);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [pin, setPin] = useState("");
  const { t } = useTranslation();

  const pinInputRef: MutableRefObject<PinInput | null | undefined> =
    useRef<PinInput | null>();

  const unlock = () => {
    if (pin === UnlockCode.fromId(writingTaskId).value.toString()) {
      void document.documentElement.requestFullscreen();
      isOpen(false);
      onClose();
    } else {
      pinInputRef.current?.clear();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      unlock();
    }
  };

  return (
    <Modal
      style={modalStyles}
      isOpen={open}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      <Container>
        <TitleLabel>
          <FeaturedIcon color="error" size={20}>
            <Lock />
          </FeaturedIcon>
          <Title>{t("writingAssignmentPage.securityModalTitle")}</Title>
        </TitleLabel>
        <Body>{t("writingAssignmentPage.securityModalContent")}</Body>
        <Divider />
        {!buttonPressed && (
          <Button
            onClick={() => {
              setButtonPressed(true);
            }}
          >
            {t("writingAssignmentPage.securityModalAction")}
          </Button>
        )}
        {buttonPressed && (
          <InputContainer onKeyDown={handleKeyDown}>
            <PinInput
              length={4}
              focus={true}
              initialValue=""
              secret
              type="numeric"
              inputMode="number"
              autoSelect={true}
              style={{
                padding: "10px",
                alignSelf: "center",
                width: "fit-content",
              }}
              inputStyle={{
                borderColor: "grey",
                borderRadius: 10,
              }}
              inputFocusStyle={{ borderColor: "black" }}
              onComplete={(value) => {
                setPin(value);
              }}
              ref={(p) => (pinInputRef.current = p)}
            />

            <ArrowButton onClick={unlock}>
              <RightArrow />
            </ArrowButton>
          </InputContainer>
        )}
      </Container>
    </Modal>
  );
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "850px",
    borderRadius: "12px",
    border: "1px",
    padding: "0",
    backgroundColor: theme.colors.base.white,
    color: theme.colors.base.black,
    boxShadow: "0 20px 24px -4px " + theme.colors.gray[900] + "08",
  },
  overlay: {
    background: theme.colors.gray[700] + "B3",
    backdropFilter: "blur(4px)",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`;

const TitleLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Title = styled.span`
  ${theme.typography.xl.semibold}
  color: ${theme.colors.gray[900]};
`;

const Body = styled.span`
  ${theme.typography.sm.regular}
  color: ${theme.colors.gray[600]};
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.gray[200]};
  padding: 0 10px 10px;
`;

const Button = styled.button`
  ${theme.typography.md.semibold}
  color: ${theme.colors.gray[700]};
  padding: 10px 18px;
  border: 1px solid ${theme.colors.gray[300]};
  border-radius: 100px;
  align-self: end;
`;

const ArrowButton = styled.button`
  padding: 10px, 18px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-self: center;
  padding: 10px;
`;

const RightArrow = styled(ArrowIcon)`
  background: ${theme.colors.primary[700]};
  border-radius: 999px;
  scale: 2;
  margin-top: 5px;
`;
