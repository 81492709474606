import clsx from "clsx";
import type { PropsWithChildren } from "react";
import styled from "styled-components";

import { Card } from "@/application/ui/lib/Card.tsx";
import { theme } from "@/application/ui/theme/theme.ts";

interface Props {
  color: "white" | "gray";
  shadowless?: boolean;
}

export const TaskCard = ({
  children,
  color,
  shadowless = false,
}: PropsWithChildren<Props>) => {
  return (
    <Container
      className={clsx({ shadowless })}
      color={
        color === "white" ? theme.colors.base.white : theme.colors.gray[50]
      }
    >
      {children}
    </Container>
  );
};

const Container = styled(Card)`
  ${({ color }) => `
     background-color: ${color};
  `};
  flex-direction: column;
  width: 360px;
  height: 350px;
  padding: 16px;
  gap: 18px;

  &.shadowless {
    border: 1px solid ${theme.colors.gray[300]};
    box-shadow: none;
  }
`;

TaskCard.Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

TaskCard.Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

TaskCard.Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;
