import type { StudentWritingTask } from "../../../../../domain/StudentDashboard";
import { TaskCard } from "../TeacherWritingTaskCard/TaskCard";
import { StudentHeaderFactory } from "./StudentHeaderFactory";
import { StudentTaskInformations } from "./StudentTaskInformations";
import { StudentWritingTaskCardActions } from "./StudentWritingTaskCardActions";

export const COMPOSITION_PAGE_URL = "/etudiant/composition";
export const ASSIGNMENT_PAGE_URL = "/etudiant/travail";

interface Props {
  writingTask: StudentWritingTask;
}

export const StudentWritingTaskCard = ({ writingTask }: Props) => {
  return (
    <TaskCard color="white">
      <TaskCard.Header>
        <StudentHeaderFactory writingTask={writingTask} />
      </TaskCard.Header>
      <TaskCard.Body>
        <StudentTaskInformations writingTask={writingTask} />
      </TaskCard.Body>
      <TaskCard.Footer>
        <StudentWritingTaskCardActions writingTask={writingTask} />
      </TaskCard.Footer>
    </TaskCard>
  );
};
