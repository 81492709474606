import type { Grade } from "@emilia/backend/src/common/grade/model/Grade";
import type { GradedCriteria } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import type { GradingScaleType } from "@emilia/backend/src/common/grade/model/GradingScaleType";
import type { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";

import type { PersonName } from "./PersonName";

export type RevisionMap = {
  [id: string]: RevisionModel;
};

export enum AssignmentStatus {
  WAIT_FOR_CORRECTION = "WAIT_FOR_CORRECTION",
  CORRECTED = "CORRECTED",
  GRADED = "GRADED",
  GRADING = "GRADING",
  WRITING_IN_PROGRESS = "WRITING_IN_PROGRESS",
  CORRECTION_REJECTED = "CORRECTION_REJECTED",
  NOT_SUBMITTED = "NOT_SUBMITTED",
  NULL = "NULL",
}

export type RevisionCategory = {
  mainCategory: Category;
  subCategory: string | null;
};

export type RevisionModel = {
  original: string;
  revised: string;
  category: RevisionCategory;
  penalized: boolean;
};

export type TeacherComment = {
  id: string;
  startIndex: number;
  endIndex: number;
  text: string;
};

export type StudentInformation = {
  id: string;
  name: PersonName;
};

export type WritingTaskInformation = {
  id: string;
  name: string;
  instructions: string;
};

export type Assignment = {
  id: string;
  studentInformation: StudentInformation;
  content: string;
  status: AssignmentStatus;
  revisions?: RevisionMap;
  comments: TeacherComment[];
  gradedCriteria: GradedCriteria;
  globalGrade?: Grade;
  comment?: string;
  recordUrl: string | undefined;
  flagged: boolean;
};

export type ConsultationAssignment = Assignment & {
  gradingScaleType?: GradingScaleType;
  writingTaskInformation: WritingTaskInformation;
};
