import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

type FlagsConfig = {
  maintenance: boolean;
  draftWritingTask: boolean;
  spavoc: boolean;
  sections: boolean;
};

export const FlagsContext = createContext<FlagsConfig>({
  maintenance: false,
  draftWritingTask: false,
  spavoc: false,
  sections: false,
});

enum ConfigCatKeys {
  maintenance = "maintenance",
  draftWritingTask = "draftwritingtask",
  spavoc = "spavoc",
  sections = "sections",
}

export const useFlags = () => {
  const context = useContext(FlagsContext);

  if (!context) {
    throw new Error("useFlags cannot be used outside FlagsProvider");
  }

  return context;
};

type Props = {
  getFlag: (
    key: ConfigCatKeys,
    defaultValue: boolean,
  ) => { value: boolean; loading: boolean };
} & PropsWithChildren;

export const FlagsProvider = ({ children, getFlag }: Props) => {
  const maintenance = getFlag(ConfigCatKeys.maintenance, false).value;
  const draftWritingTask = getFlag(ConfigCatKeys.draftWritingTask, false).value;
  const spavoc = getFlag(ConfigCatKeys.spavoc, false).value;
  const sections = getFlag(ConfigCatKeys.sections, false).value;

  return (
    <FlagsContext.Provider
      value={{
        maintenance,
        draftWritingTask,
        spavoc,
        sections,
      }}
    >
      {children}
    </FlagsContext.Provider>
  );
};
