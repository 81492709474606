import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";
import type { Section } from "@/application/domain/Teacher/TeacherDashboard";

export const SectionsClient = {
  fetchSections: async (): Promise<Section[]> => {
    const { data } = await AuthenticatedHttpClient.get<Section[]>("/section");
    return data;
  },

  createSection: async (name: string): Promise<void> => {
    await AuthenticatedHttpClient.post("/section", {
      name,
    });
  },

  renameSection: async (sectionId: string, name: string): Promise<void> => {
    await AuthenticatedHttpClient.put(`/section/${sectionId}`, {
      name,
    });
  },
};
