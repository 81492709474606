import { useQuery } from "@tanstack/react-query";

import { WritingTaskClient } from "@/application/client/WritingTaskClient.ts";

export const useLinkedDraftWritingTask = (
  writingTaskId: string,
  open: boolean,
) => {
  const { data: linkedTasks, isLoading } = useQuery({
    queryKey: ["writingTasks", writingTaskId, "deleteLinked"],
    queryFn: () => WritingTaskClient.getLinkedGroups(writingTaskId),
    enabled: open,
  });

  return { linkedTasks, isLoading };
};
