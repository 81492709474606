import { useQuery } from "@tanstack/react-query";

import { SectionsClient } from "@/application/client/SectionsClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

export const useSections = (sectionsFlagActive: boolean) => {
  const { data: sections, isError } = useQuery({
    queryKey: [...TeacherQueryKeys.sections],
    queryFn: () => SectionsClient.fetchSections(),
    retry: false,
    enabled: sectionsFlagActive,
  });

  return { sections: sections ?? [], isError };
};
