import { useTranslation } from "react-i18next";

import type { StudentWritingTask } from "../../../../../domain/StudentDashboard";
import {
  InfoContainer,
  InfoLabel,
  InfosContainer,
  InfoValue,
} from "../TeacherWritingTaskCard/Content/TaskInformations";
import { WritingTaskInformation } from "../WritingTaskInformation";

type Props = {
  writingTask: StudentWritingTask;
};

export const StudentTaskInformations = ({ writingTask }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <WritingTaskInformation>
        <WritingTaskInformation.Name>
          {writingTask.name}
        </WritingTaskInformation.Name>
        <WritingTaskInformation.Instructions>
          {writingTask.instructions}
        </WritingTaskInformation.Instructions>
      </WritingTaskInformation>
      <InfosContainer>
        <InfoContainer>
          <InfoLabel>
            {t("studentWritingTaskCardInformations.redactionType")}
          </InfoLabel>
          <InfoValue>
            {t(`redactionType.${writingTask.redactionType}`)}
          </InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>
            {t("studentWritingTaskCardInformations.taskType")}
          </InfoLabel>
          <InfoValue>{t(`taskType.${writingTask.taskType}`)}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>
            {t("studentWritingTaskCardInformations.wordsCount")}
          </InfoLabel>
          <InfoValue>{writingTask.numberOfWords ?? "-"}</InfoValue>
        </InfoContainer>
      </InfosContainer>
    </>
  );
};
