import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { ReactComponent as LockIcon } from "@/assets/passcode-lock.svg";

import type { StudentWritingTask } from "../../../../../domain/StudentDashboard.ts";
import { tooltipStyle } from "../../../../lib/tooltip/tooltipStyle.ts";

export type Props = {
  writingTask: StudentWritingTask;
};

export const StudentWritingTaskContextMenu = ({ writingTask }: Props) => {
  const displayCode = writingTask.taskType === "summative";
  const anchor = `anchor${writingTask.id}`;
  const { t } = useTranslation();
  return (
    <Container>
      {displayCode && (
        <>
          <div id={anchor}>
            <LockIcon />
          </div>
          <Tooltip
            anchorSelect={`#${anchor}`}
            place="bottom"
            offset={-5}
            style={tooltipStyle}
          >
            {t("securedModeTooltip")}
          </Tooltip>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;
