import { t } from "i18next";
import type { SyntheticEvent } from "react";
import { useState } from "react";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button";
import { Input } from "@/application/ui/lib/inputs/Input";
import { theme } from "@/application/ui/theme/theme";
import { ReactComponent as CheckIcon } from "@/assets/check.svg";
import { ReactComponent as CloseIcon } from "@/assets/x-close.svg";

export type SectionHeaderEditionProps = {
  onSubmit: (name: string) => void;
  onCancel: () => void;
  name?: string;
};

export const SectionHeaderEdition = ({
  onSubmit,
  onCancel,
  name,
}: SectionHeaderEditionProps) => {
  const [value, setValue] = useState<string>(name ?? "");

  const onChange = ({ nativeEvent }: SyntheticEvent) => {
    setValue((nativeEvent.target as HTMLInputElement).value);
  };

  const hasValue = !!value;

  return (
    <Container>
      <StyledInput
        wide
        placeholder={t("sections.createSectionPlaceholder")}
        onChange={onChange}
        value={value}
        autoFocus
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <>
          <CloseButton
            variant="text"
            hierarchy="neutral"
            onClick={(e) => {
              setValue("");
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            {t("sections.clear")}
          </CloseButton>
          <CloseButton
            variant="text"
            hierarchy="neutral"
            size="xs"
            disabled={!hasValue}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onSubmit(value);
            }}
          >
            <CheckIcon
              width={20}
              height={20}
              color={
                !hasValue ? theme.colors.gray[300] : theme.colors.base.black
              }
            />
          </CloseButton>
        </>
      </StyledInput>
      <CloseButton
        variant="text"
        hierarchy="neutral"
        size="xs"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onCancel();
        }}
      >
        <CloseIcon width={20} height={20} />
      </CloseButton>
    </Container>
  );
};

const StyledInput = styled(Input)`
  ${theme.typography.md.semibold};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CloseButton = styled(Button)`
  cursor: pointer;
  width: auto;
`;
