import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { useOpenWritingTask } from "@/application/ui/pages/Dashboard/hooks/useOpenWritingTask.ts";
import { ReactComponent as PlayCircleIcon } from "@/assets/play-circle.svg";

import { theme } from "../../../../../theme/theme.ts";
import { WritingTaskCardActions } from "./WritingTaskCardActions.tsx";

interface Props {
  writingTask: TeacherWritingTask;
  homeroomId: string;
  canCloseTask: boolean;
}

export const ClosedActions = ({
  writingTask,
  homeroomId,
  canCloseTask,
}: Props) => {
  const { t } = useTranslation();
  const { openWritingTask } = useOpenWritingTask();

  return (
    <Actions>
      <WritingTaskCardActions
        homeroomId={homeroomId}
        writingTask={writingTask}
      />
      {!canCloseTask && (
        <TaskButton
          hierarchy="secondary"
          size="sm"
          onClick={() => openWritingTask(writingTask.id)}
        >
          <PlayCircleIcon />
          {t("teacherWritingTaskCardAction.openWritingTask")}
        </TaskButton>
      )}
    </Actions>
  );
};

const TaskButton = styled(Button)`
  > * {
    stroke: ${theme.colors.gray[700]};
    width: 20px;
    height: 20px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
