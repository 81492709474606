export type AbsolutePosition = {
  startIndex: number;
  endIndex: number;
};

export class PositionCalculator {
  private static readonly enteringNode = 1;
  private static readonly crossingNode = 2;
  private static readonly inNode = 1;
  private static readonly notInNode = 0;

  static calculateAbsolutePosition(
    selection: Selection,
    textRootElement: HTMLDivElement,
  ): AbsolutePosition {
    //Store selected nodes
    const range = selection.getRangeAt(0);
    const selectedNodes = range.cloneContents();
    const currentNode = this.findCurrentNode(
      range.startContainer.parentNode!,
      textRootElement,
    );

    //calculate startIndex (sums previous nodes length)
    let startIndex = range.startOffset;
    let prevSibling;
    while ((prevSibling = (prevSibling || currentNode).previousSibling)) {
      if (this.isCorrection(prevSibling)) {
        const OriginalText = prevSibling.childNodes[1];
        startIndex += (OriginalText.textContent || OriginalText.nodeValue || "")
          .length;
      } else if (this.isText(prevSibling)) {
        startIndex += (prevSibling.textContent || prevSibling.nodeValue || "")
          .length;
      }
    }

    //calculate endIndex
    let selectionOffset = this.isCorrection(currentNode)
      ? this.inNode
      : this.notInNode;
    const nbElementSelected = selectedNodes.childElementCount;
    //count line breaks added by the insertion of div that are only visible by the select
    selectedNodes.childNodes.forEach((node, index) => {
      if (this.isCorrection(node) && index === nbElementSelected - 1) {
        selectionOffset += this.enteringNode;
      } else if (this.isCorrection(node)) {
        selectionOffset += this.crossingNode;
      }
    });

    const endIndex = startIndex + selection.toString().length - selectionOffset;
    return { startIndex, endIndex };
  }

  static calculateNodeRange(
    startIndex: number,
    endIndex: number,
    textRootElement: Node,
  ) {
    let startNode;
    let startNodeOffset;
    let endNode;
    let endNodeOffset;

    let cursor = 0;
    let crossedEmptyNodeCount = 0;
    for (let i = 0; i < textRootElement.childNodes.length; i++) {
      let node = textRootElement.childNodes[i];

      let length = 0;
      if (this.isCorrection(node)) {
        const OriginalText = node.childNodes[1];
        length = this.textNodeLenght(OriginalText);
        node = this.findLeafNode(node.childNodes[1]);
        crossedEmptyNodeCount =
          length === 0 && startNode
            ? crossedEmptyNodeCount + 1
            : crossedEmptyNodeCount;
      } else if (this.isText(node)) {
        length += this.textNodeLenght(node);
        node = this.findLeafNode(node);
      }
      cursor += length;
      if (!startNode && cursor > startIndex) {
        startNode = node;
        startNodeOffset = length - (cursor - startIndex);
      }

      if (!endNode && cursor > endIndex) {
        endNode = node;
        endNodeOffset = length - (cursor - endIndex) + crossedEmptyNodeCount;
        break;
      }
    }

    return {
      startNode,
      startNodeOffset,
      endNode,
      endNodeOffset,
    };
  }

  private static isCorrection(node: Node) {
    return node.childNodes.length === 2;
  }

  private static isText(node: Node) {
    return node.childNodes.length === 1;
  }

  private static textNodeLenght(node: Node) {
    return (node.textContent || node.nodeValue || "").length;
  }

  private static findCurrentNode(
    node: ParentNode,
    textRootElement: HTMLDivElement,
  ): ParentNode {
    const parentNode = node.parentNode;
    return parentNode === null || parentNode === textRootElement
      ? node
      : this.findCurrentNode(parentNode, textRootElement);
  }
  private static findLeafNode(node: ChildNode): ChildNode {
    return node.hasChildNodes() ? this.findLeafNode(node.childNodes[0]) : node;
  }
}
