import { useMutation, useQueryClient } from "@tanstack/react-query";

import { SectionsClient } from "@/application/client/SectionsClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

interface CreateSection {
  name: string;
}

export const useCreateSection = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: createSection, isLoading } = useMutation({
    mutationFn: ({ name }: CreateSection) => SectionsClient.createSection(name),

    onSuccess: async () => {
      await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
      await queryClient.invalidateQueries(TeacherQueryKeys.sections);
    },
  });

  return { createSection, isLoading };
};
