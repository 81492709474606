import { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { TeacherWritingTask } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { ReactComponent as StopCircleIcon } from "@/assets/stop-circle.svg";
import { ReactComponent as StopIcon } from "@/assets/stop-circle.svg";

import { FeaturedIcon } from "../../../../../lib/icon/FeaturedIcon.tsx";
import { theme } from "../../../../../theme/theme.ts";
import type { CloseWritingTaskConfirmationDialogRef } from "../../CloseWritingTaskConfirmationDialog.tsx";
import { CloseWritingTaskConfirmationDialog } from "../../CloseWritingTaskConfirmationDialog.tsx";
import { WritingTaskCardActions } from "./WritingTaskCardActions.tsx";

interface Props {
  writingTask: TeacherWritingTask;
  homeroomId: string;
  canCloseTask: boolean;
}

export const OpenActions = ({
  writingTask,
  homeroomId,
  canCloseTask,
}: Props) => {
  const { t } = useTranslation();
  const confirmationDialogRef =
    useRef<CloseWritingTaskConfirmationDialogRef>(null);
  const handleCloseTask = () => {
    confirmationDialogRef.current?.display(writingTask.id);
  };
  return (
    <Actions>
      <WritingTaskCardActions
        homeroomId={homeroomId}
        writingTask={writingTask}
      />
      {canCloseTask && (
        <>
          <TaskButton
            variant="borderless"
            hierarchy="neutral"
            size="sm"
            onClick={() => handleCloseTask()}
          >
            <StopCircleIcon />
            {t("teacherWritingTaskCardAction.closeWritingTask")}
          </TaskButton>
          <CloseWritingTaskConfirmationDialog
            icon={
              <FeaturedIcon color="success" size={20}>
                <StopIcon />
              </FeaturedIcon>
            }
            title={t("closeWritingTaskConfirmationDialog.title")}
            confirmLabel={t("yes")}
            cancelLabel={t("cancel")}
            ref={confirmationDialogRef}
          />
        </>
      )}
    </Actions>
  );
};

const TaskButton = styled(Button)`
  background: ${theme.colors.gray[50]} !important;
  color: ${theme.colors.gray[700]} !important;
  > * {
    stroke: ${theme.colors.gray[700]};
    width: 20px;
    height: 20px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
