import type {
  FrontendAssignmentResponseDto,
  FrontendConsultationAssignmentResponseDto,
  GradedCriterionDto,
  RevisionDto,
} from "@api/FrontendAssignmentResponse.dto.ts";
import {
  EvaluationCriterionResponseDto,
  RevisionCategoryDto,
} from "@api/FrontendAssignmentResponse.dto.ts";
import { Grade } from "@emilia/backend/src/common/grade/model/Grade";
import type { GradedCriteria } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import { EvaluationCriterion } from "@emilia/backend/src/common/grade/model/GradedCriteria";
import type { GradingScaleType } from "@emilia/backend/src/common/grade/model/GradingScaleType";
import { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";
import { keyBy, mapValues } from "lodash";

import type {
  Assignment,
  ConsultationAssignment,
  RevisionMap,
} from "@/application/domain/Assignment.ts";
import { AssignmentStatus } from "@/application/domain/Assignment.ts";

import { PersonName } from "../../domain/PersonName";

export class AssignmentMapper {
  static fromDto(
    assignmentResponseDto: FrontendAssignmentResponseDto,
  ): Assignment {
    return {
      id: assignmentResponseDto.id,
      studentInformation: {
        id: assignmentResponseDto.studentId,
        name: new PersonName(
          assignmentResponseDto.student.firstName,
          assignmentResponseDto.student.lastName,
        ),
      },
      content: assignmentResponseDto.content,
      status:
        AssignmentStatus[
          assignmentResponseDto.status as keyof typeof AssignmentStatus
        ],
      revisions: this.toRevisionMap(assignmentResponseDto.revisions),
      comments:
        assignmentResponseDto.comments?.map((commentDto) => ({
          id: commentDto.id,
          startIndex: commentDto.startIndex,
          endIndex: commentDto.endIndex,
          text: commentDto.text,
        })) ?? [],
      gradedCriteria: this.toGradedCriteria(
        assignmentResponseDto.gradedCriteria,
      ),
      globalGrade: assignmentResponseDto.globalGrade
        ? Grade.of(assignmentResponseDto.globalGrade)
        : undefined,
      comment: assignmentResponseDto.comment ?? "",
      recordUrl: assignmentResponseDto.recordUrl,
      flagged: assignmentResponseDto.flagged,
    };
  }

  static fromConsultationDto(
    assignmentResponseDto: FrontendConsultationAssignmentResponseDto,
  ): ConsultationAssignment {
    return {
      ...this.fromDto(assignmentResponseDto),
      gradingScaleType:
        assignmentResponseDto.gradingScaleType as GradingScaleType,
      writingTaskInformation: {
        id: assignmentResponseDto.writingTask.id,
        name: assignmentResponseDto.writingTask.name,
        instructions: assignmentResponseDto.writingTask.instructions,
      },
    };
  }

  private static toGradedCriteria(
    gradedCriteria: GradedCriterionDto[],
  ): GradedCriteria {
    return {
      [EvaluationCriterion.ADAPTATION]: this.findByCriterion(
        gradedCriteria,
        EvaluationCriterionResponseDto.ADAPTATION,
      ),
      [EvaluationCriterion.COHERENCE]: this.findByCriterion(
        gradedCriteria,
        EvaluationCriterionResponseDto.COHERENCE,
      ),
      [EvaluationCriterion.SYNTAX]: this.findByCriterion(
        gradedCriteria,
        EvaluationCriterionResponseDto.SYNTAX,
      ),
      [EvaluationCriterion.VOCABULARY]: this.findByCriterion(
        gradedCriteria,
        EvaluationCriterionResponseDto.VOCABULARY,
      ),
      [EvaluationCriterion.ORTHOGRAPHY]: this.findByCriterion(
        gradedCriteria,
        EvaluationCriterionResponseDto.ORTHOGRAPHY,
      ),
    };
  }

  private static findByCriterion(
    gradedCriteria: GradedCriterionDto[],
    criterion: EvaluationCriterionResponseDto,
  ): Grade | undefined {
    const grade = gradedCriteria.find(
      (gradedCriterion) => gradedCriterion.criterion === criterion,
    )?.grade;

    return grade && Grade.of(grade);
  }

  private static toRevisionMap(
    revisions: RevisionDto[] | undefined,
  ): RevisionMap {
    const revisionMap: RevisionMap = mapValues(
      keyBy(revisions, "id"),
      (revision) => ({
        revised: revision.revised,
        category: {
          mainCategory: AssignmentMapper.fromRevisionCategoryDtoToCategory(
            revision.category.mainCategory,
          ),
          subCategory: revision.category.subCategory,
        },
        original: revision.original,
        penalized: revision.penalized,
      }),
    );

    if (!revisionMap) return {};

    return revisionMap;
  }

  static fromRevisionCategoryDtoToCategory(
    revisionCategoryDto: RevisionCategoryDto,
  ): Category {
    switch (revisionCategoryDto) {
      case RevisionCategoryDto.GRAMMAR:
        return Category.GRAMMAR;
      case RevisionCategoryDto.PUNCTUATION:
        return Category.PUNCTUATION;
      case RevisionCategoryDto.SPELLING:
        return Category.SPELLING;
      case RevisionCategoryDto.SYNTAX:
        return Category.SYNTAX;
      case RevisionCategoryDto.VOCABULARY:
        return Category.VOCABULARY;
      default:
        throw new Error(`cannot map ${revisionCategoryDto} to Category`);
    }
  }
}
