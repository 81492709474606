import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "react-tiny-popover";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button";
import { tooltipStyle } from "@/application/ui/lib/tooltip/tooltipStyle";
import { theme } from "@/application/ui/theme/theme";
import { ReactComponent as PlusIcon } from "@/assets/plus.svg";

export type CreationContextMenuAction = {
  label: string;
  children: string | JSX.Element;
  callback: () => void;
  disabled?: boolean;
  className?: string;
  tooltipMessage?: string;
  keepOpenOnClick?: boolean;
};

export interface CreationContextMenuActionsProps {
  actions: CreationContextMenuAction[];
}

export const TeacherDashboardContextMenu = ({
  actions,
}: CreationContextMenuActionsProps) => {
  const { t } = useTranslation();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const openContextMenu: React.ComponentProps<typeof Button>["onClick"] = (
    e,
  ) => {
    e.preventDefault();
    setMenuOpen(true);
  };
  const closeContextMenu = () => setMenuOpen(false);
  return (
    <Container>
      <Popover
        isOpen={isMenuOpen}
        positions={["bottom", "left"]}
        align="start"
        onClickOutside={closeContextMenu}
        content={
          <ContextualMenu>
            {actions.map(
              ({
                label,
                disabled,
                callback,
                children,
                className,
                tooltipMessage,
              }) => (
                <ContextualMenuAction
                  key={label}
                  className={className}
                  variant="text"
                  hierarchy="neutral"
                  disabled={disabled}
                  onClick={(e) => {
                    e.preventDefault();
                    callback();
                    closeContextMenu();
                  }}
                >
                  {className && tooltipMessage && disabled && (
                    <Tooltip
                      anchorSelect={"." + className}
                      place="bottom"
                      style={tooltipStyle}
                    >
                      {tooltipMessage}
                    </Tooltip>
                  )}
                  {children}
                </ContextualMenuAction>
              ),
            )}
          </ContextualMenu>
        }
      >
        <Button onClick={openContextMenu}>
          {t("create")}
          <PlusIcon />
        </Button>
      </Popover>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const ContextualMenu = styled.section`
  ${theme.typography.md.regular}
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${theme.radius.small};
  background-color: ${theme.colors.gray[50]};
  box-shadow: ${theme.shadowAndBlur.large};
  width: fit-content;
  border: ${theme.borders.primary};
`;

const ContextualMenuAction = styled(Button)`
  justify-content: flex-start;
`;
