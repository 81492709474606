import { Accordion } from "@szhsin/react-accordion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type {
  HomeroomListing,
  WritingTaskListing,
} from "@/application/domain/HomeroomListing";
import { Page } from "@/application/ui/lib/Page";
import { LoadingPage } from "@/application/ui/lib/spinner/LoadingPage";
import { TimeoutPage } from "@/application/ui/lib/Timeout/TimeoutPage";
import { theme } from "@/application/ui/theme/theme";

import type { TeacherDashboard } from "../../../../domain/Teacher/TeacherDashboard";
import { useFlags } from "../../App/FlagsProvider";
import { useCreateSection } from "../hooks/useCreateSection";
import { useTeacherHomeroomDashboard } from "../hooks/useTeacherDashboard";
import { NoWritingTasks } from "./NoWritingTasks";
import { DashboardSection } from "./Section/DashboardSection";
import { SectionHeaderEdition } from "./Section/SectionHeaderEdition";
import { WritingTaskCard } from "./TeacherWritingTaskCard/WritingTaskCard";

interface Props {
  homeroomId: string;
  openCreationFor: (homeroomId: string, sectionId?: string) => void;
  openUpdateFor: (homeroom: HomeroomListing, writingTaskId: string) => void;
  openUpdateForDraft: (
    homeroom: HomeroomListing,
    writingTaskId: string,
    sectionId?: string,
  ) => void;
  onOpenGradePanel: (task: WritingTaskListing, homeroomName: string) => void;
  onSectionCreationCompleted: () => void;
  isCreatingSection: boolean;
  includeMasked: boolean;
}

export const TeacherDashboardSections = ({
  homeroomId,
  openCreationFor,
  openUpdateFor,
  openUpdateForDraft,
  onOpenGradePanel,
  onSectionCreationCompleted,
  isCreatingSection,
  includeMasked,
}: Props) => {
  const { t } = useTranslation();
  const { sections: sectionsFlag } = useFlags();
  const { dashboard, isError: isErrorDashboard } = useTeacherHomeroomDashboard(
    homeroomId,
    includeMasked,
  );

  const { createSection: createSectionMutation } = useCreateSection();

  const onCreateSection = (name: string) => {
    void createSectionMutation({ name }).then(onSectionCreationCompleted);
  };

  if (isErrorDashboard) {
    return (
      <Page>
        <TimeoutPage />
      </Page>
    );
  }

  if (!dashboard) {
    return (
      <Page>
        <LoadingPage />
      </Page>
    );
  }

  return (
    <Group aria-labelledby={dashboard.id}>
      {sectionsFlag && isCreatingSection && (
        <SectionHeaderEdition
          onCancel={onSectionCreationCompleted}
          onSubmit={onCreateSection}
        />
      )}
      {sectionsFlag ? (
        <Accordion allowMultiple>
          {dashboard.sections.map((section, index) => (
            <DashboardSection
              initialEntered={index === 0}
              homeroomId={dashboard.id}
              allowDirectCreation
              section={section}
              key={section.id}
              openCreationFor={() => openCreationFor(dashboard.id, section.id)}
              openUpdateFor={(taskId) => openUpdateFor(dashboard, taskId)}
              openUpdateForDraft={(taskId) =>
                openUpdateForDraft(dashboard, taskId, section.id)
              }
              onOpenGradePanel={(task) =>
                onOpenGradePanel(task, dashboard.name)
              }
            />
          ))}
          <DashboardSection
            homeroomId={dashboard.id}
            hideActions
            section={{
              id: "0",
              name: t("sections.unclassified"),
              tasks: dashboard.sectionlessTasks,
            }}
            openCreationFor={() => openCreationFor(dashboard.id)}
            openUpdateFor={(taskId) => openUpdateFor(dashboard, taskId)}
            openUpdateForDraft={(taskId) =>
              openUpdateForDraft(dashboard, taskId)
            }
            onOpenGradePanel={(task) => onOpenGradePanel(task, dashboard.name)}
          />
        </Accordion>
      ) : (
        <LegacyDashboardWritingTasksList
          dashboard={dashboard}
          openCreationFor={openCreationFor}
          openUpdateFor={openUpdateFor}
          openUpdateForDraft={openUpdateForDraft}
          onOpenGradePanel={onOpenGradePanel}
        />
      )}
    </Group>
  );
};

type LecaryProps = {
  dashboard: TeacherDashboard;
  openCreationFor: (homeroomId: string) => void;
  openUpdateFor: (homeroom: HomeroomListing, writingTaskId: string) => void;
  openUpdateForDraft: (
    homeroom: HomeroomListing,
    writingTaskId: string,
  ) => void;
  onOpenGradePanel: (task: WritingTaskListing, homeroomName: string) => void;
};

const LegacyDashboardWritingTasksList = ({
  dashboard,
  openCreationFor,
  openUpdateFor,
  openUpdateForDraft,
  onOpenGradePanel,
}: LecaryProps) => {
  const { t } = useTranslation();

  const hasTasks = dashboard.sectionlessTasks.length > 0;
  return hasTasks ? (
    <WritingTasks aria-label={t("writingTaskListing")}>
      {dashboard.sectionlessTasks.map((task) => (
        <WritingTaskCard
          key={task.id}
          writingTask={task}
          onUpdateWritingTask={() => openUpdateFor(dashboard, task.id)}
          onUpdateDraftWritingTask={() =>
            openUpdateForDraft(dashboard, task.id)
          }
          seeStudentsList={() => onOpenGradePanel(task, dashboard.name)}
          homeroomId={dashboard.id}
        />
      ))}
    </WritingTasks>
  ) : (
    <NoWritingTasks onCreateClick={() => openCreationFor(dashboard.id)} />
  );
};

const WritingTasks = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  padding-top: 0;
`;

const Group = styled.section`
  &.separator {
    border-bottom: 1px solid ${theme.colors.gray[300]};
  }
`;
