export const TeacherQueryKeys = {
  dashboard: ["teacher", "dashboard"],
  sections: ["teacher", "sections"],
  homerooms: ["teacher", "homerooms"],
  revisionsState: ["teacher", "revisionsState"],
  flaggedState: ["teacher", "flagged"],
  dashboardWritingTasks: ["teacher", "writingTaskDashboard"],
  lostAssignment: ["teacher", "lostAssignment"],
  writingTaskSummary: (writingTaskId: string | undefined) => [
    "teacher",
    "writingTasks",
    writingTaskId,
    "grades",
  ],
  reportDashboard: ["teacher", "reportDashboard"],
  gradeReport: (writingTaskId: string, studentId?: string) => [
    "teacher",
    "gradeReport",
    writingTaskId,
    studentId,
  ],
  reportSummary: (writingTaskId: string) => [
    "teacher",
    "reportSummary",
    writingTaskId,
  ],
  gradedStudents: (writingTaskId: string) => [
    "teacher",
    "gradedStudents",
    writingTaskId,
  ],
  globalRanking: (writingTaskId: string, criteria?: string) => [
    "teacher",
    "globalRanking",
    writingTaskId,
    criteria,
  ],
  subCriterionDistribution: (
    writingTaskId: string,
    revisionCriteria: string,
    studentId?: string,
  ) => ["teacher", "subCriterion", writingTaskId, revisionCriteria, studentId],
};
