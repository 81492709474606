import type { Ref } from "react";
import React, { useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import type { HomeroomListing } from "@/application/domain/HomeroomListing.ts";
import { Button } from "@/application/ui/lib/button/Button.tsx";
import { RightPanel } from "@/application/ui/lib/RightPanel.tsx";
import { LoadingSpinner } from "@/application/ui/lib/spinner/LoadingSpinner.tsx";
import { ToastService } from "@/application/ui/lib/toast/ToastService.tsx";
import { WritingTaskContent } from "@/application/ui/pages/Dashboard/components/WritingTaskPanel/WritingTaskContent.tsx";
import { ReactComponent as AlertCircle } from "@/assets/alert-circle.svg";

import type { ConfirmationDialogRef } from "../../../lib/form/ConfirmationDialog";
import { ConfirmationDialog } from "../../../lib/form/ConfirmationDialog";
import { FeaturedIcon } from "../../../lib/icon/FeaturedIcon";
import { useFlags } from "../../App/FlagsProvider";
import { useDraftWritingTask } from "../hooks/useDraftWritingTask";
import { useDraftWritingTaskUpdate } from "../hooks/useDraftWritingTaskUpdate";
import { useSections } from "../hooks/useSections";

export interface PanelDraftUpdateRef {
  openForDraftUpdate: (
    incomingHomeroom: HomeroomListing,
    incomingTaskId: string,
    incomingSectionId?: string,
  ) => void;
}

export const DraftWritingTaskUpdatePanel = React.forwardRef(
  (_, ref: Ref<PanelDraftUpdateRef>) => {
    const { t } = useTranslation();
    const [homeroom, setHomeroom] = useState<HomeroomListing | undefined>(
      undefined,
    );
    const [sectionId, setSectionId] = useState<string | undefined>(undefined);
    const [writingTaskId, setWritingTaskId] = useState<string | undefined>(
      undefined,
    );
    const { writingTask, isLoading, refetch } = useDraftWritingTask(
      writingTaskId!,
    );
    const { sections: sectionsFlag } = useFlags();
    const { sections } = useSections(sectionsFlag);
    const { updateDraftWritingTask, updateIsLoading } =
      useDraftWritingTaskUpdate(homeroom?.id ?? "", writingTaskId ?? "");
    const confirmationDialogRef = useRef<ConfirmationDialogRef>(null);
    const formRef = useRef<HTMLFormElement>(null);

    useImperativeHandle(ref, () => ({
      openForDraftUpdate: (
        incomingHomeroom,
        incomingTaskId,
        incomingSectionId,
      ) => {
        setWritingTaskId(incomingTaskId);
        setHomeroom(incomingHomeroom);
        setSectionId(incomingSectionId);
      },
    }));

    const submit = () => {
      if (formRef.current) {
        const formData = new FormData(formRef.current);
        updateDraftWritingTask({ formData })
          .then(async () => {
            setHomeroom(undefined);
            setWritingTaskId(undefined);
            await refetch();
            ToastService.showSuccess(
              t("writingTaskUpdatedSuccessfully", {
                title: formData.get("title"),
              }),
            );
          })
          .catch(() => ToastService.showError(t("failToUpdateWritingTask")));
      }
    };

    const handleSubmit = () => {
      confirmationDialogRef.current?.display();
    };

    return (
      <form ref={formRef}>
        <RightPanel opened={!!homeroom && !!writingTaskId}>
          <RightPanel.Header>
            <RightPanel.Title>{t("writingTaskUpdate")}</RightPanel.Title>
            {!!homeroom && (
              <RightPanel.Subtitle>{`(${homeroom.name})`}</RightPanel.Subtitle>
            )}
          </RightPanel.Header>

          <RightPanel.Content>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <WritingTaskContent
                sections={
                  sections?.map((x) => ({
                    ...x,
                    selected: x.id === sectionId,
                  })) ?? []
                }
                writingTask={writingTask}
                draft={true}
              />
            )}
          </RightPanel.Content>

          <RightPanel.Footer>
            <Button disabled={isLoading} size="md" onClick={handleSubmit}>
              {isLoading ? <LoadingSpinner /> : t("update")}
            </Button>
            <Button
              data-testid="cancelUpdate"
              variant="text"
              hierarchy="neutral"
              size="md"
              disabled={updateIsLoading}
              onClick={() => {
                setHomeroom(undefined);
                setWritingTaskId(undefined);
              }}
            >
              {t("cancel")}
            </Button>
          </RightPanel.Footer>
        </RightPanel>
        <ConfirmationDialog
          icon={
            <FeaturedIcon color="warning" size={20}>
              <AlertCircle />
            </FeaturedIcon>
          }
          title={t("draftWritingTaskModificationDialog.title")}
          description={t("draftWritingTaskModificationDialog.description")}
          confirmLabel={t("yes")}
          cancelLabel={t("cancel")}
          ref={confirmationDialogRef}
          onConfirm={submit}
        />
      </form>
    );
  },
);
