import { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";
import clsx from "clsx";
import { Popover } from "react-tiny-popover";

import { RevisionPopup } from "@/application/ui/pages/Revision/components/RevisionPopup/RevisionPopup.tsx";
import { useRevisionPopup } from "@/application/ui/pages/Revision/components/RevisionPopup/useRevisionPopup.tsx";
import type { DeconstructedSelection } from "@/application/ui/pages/Revision/components/TextCorrector/segments/NormalText.tsx";
import { NormalText } from "@/application/ui/pages/Revision/components/TextCorrector/segments/NormalText.tsx";
import { Revision } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";

import { Container, Mark, Revised, RevisionBlock } from "./StyledText";

interface RevisedTextProps {
  level?: number;
  revision: Revision;
  onChange: (revision: Revision) => void;
  onRemove: () => void;
  readOnly: boolean;
  displayRevision: boolean;
}

export const RevisedText = ({
  level = 0,
  revision,
  onChange,
  onRemove,
  readOnly,
  displayRevision,
}: RevisedTextProps) => {
  const validateRevisionCategory = () => {
    if (revision.category === Category.UNSPECIFIED) onRemove();
  };
  const {
    popupIsVisible,
    showPopupInViewMode,
    startClosingCountdown,
    cancelPopupClosing,
    closePopup,
  } = useRevisionPopup({
    popupIsVisibleBaseValue: revision.category === Category.UNSPECIFIED,
    afterClosingAction: validateRevisionCategory,
  });

  const deleteRevision = () => {
    onRemove();
    closePopup();
  };

  const addRevisionForSelection = (
    index: number,
    { before, selection, after }: DeconstructedSelection,
  ) => {
    if (readOnly) {
      return;
    }

    const elements = [];

    if (before.length) {
      elements.push(before);
    }
    elements.push(Revision.create([selection]));
    if (after.length) {
      elements.push(after);
    }

    onChange(revision.insertRevisionAt(index, elements));
  };

  const style = {
    textUnderlineOffset: `${6 - 5 * level}px`,
  };

  return (
    <RevisionBlock>
      {displayRevision && (
        <Revised
          className={`${revision.penalized ? revision.category : Category.UNPENALIZED} ${revision.category} ${
            revision.penalized ? "penalized" : "not-penalized"
          }`}
        >
          {revision.revisedText}
        </Revised>
      )}
      <Container className={clsx({ isClickable: true })}>
        <span
          onMouseLeave={
            revision.category === Category.UNSPECIFIED
              ? () => {}
              : startClosingCountdown
          }
          onMouseEnter={cancelPopupClosing}
        >
          <Popover
            isOpen={popupIsVisible}
            positions={["right", "left", "top"]}
            padding={10}
            content={
              <RevisionPopup
                readOnly={readOnly}
                revision={revision}
                onClose={closePopup}
                onDelete={deleteRevision}
                onChange={onChange}
              />
            }
          >
            <Mark
              style={style}
              className={`${revision.penalized ? revision.category : Category.UNPENALIZED} ${revision.category} ${
                revision.penalized ? "penalized" : "not-penalized"
              }`}
              role="comment"
              aria-label={revision.toString()}
              id={`revision-${revision.id}`}
              onClick={showPopupInViewMode}
            >
              {revision.segments.map((segment, index) => {
                if (segment instanceof Revision) {
                  return (
                    <RevisedText
                      level={level + 1}
                      key={segment.id}
                      revision={segment}
                      onRemove={() =>
                        onChange(revision.removeRevisionAt(index))
                      }
                      onChange={(_revision) =>
                        onChange(revision.replaceAt(index, _revision))
                      }
                      readOnly={readOnly}
                      displayRevision={displayRevision}
                    />
                  );
                }

                return (
                  <NormalText
                    key={`${revision.id}-segment-${index}`}
                    text={segment}
                    onSelect={(deconstructedSelection) =>
                      addRevisionForSelection(index, deconstructedSelection)
                    }
                  />
                );
              })}
            </Mark>
          </Popover>
        </span>
      </Container>
    </RevisionBlock>
  );
};
