import type { Category } from "@emilia/backend/src/common/mistakes/domain/model/Category";
import { useEffect, useRef } from "react";
import styled from "styled-components";

import { RevisionPopupViewMode } from "@/application/ui/pages/Revision/components/RevisionPopup/RevisionPopupViewMode.tsx";
import type { Revision } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";

import { theme } from "../../../../theme/theme";

type RevisionPopupProps = {
  readOnly: boolean;
  revision: Revision;
  onDelete: () => void;
  onChange: (revision: Revision) => void;
  onClose: () => void;
};

export const RevisionPopup = ({
  readOnly,
  revision,
  onDelete,
  onChange,
  onClose,
}: RevisionPopupProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const saveRevision = (
    revisedText: string,
    category: Category,
    penalized: boolean,
  ) => {
    revision.revisedText = revisedText;
    revision.category = category;
    revision.subCategory = revision.category ? revision.subCategory : null;
    revision.penalized = penalized;

    onChange(revision);
    onClose();
  };

  const deleteRevision = () => {
    onDelete();
    onClose();
  };

  return (
    <Container ref={ref} role="dialog">
      <RevisionPopupViewMode
        readOnly={readOnly}
        revision={revision}
        onDeleteRevision={deleteRevision}
        onSave={saveRevision}
        onClose={onClose}
      />
    </Container>
  );
};

const Container = styled.div`
  ${theme.typography.md.regular}
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: ${theme.radius.large};
  background-color: ${theme.colors.gray[50]};
  box-shadow: ${theme.shadowAndBlur.xlarge};
  max-width: 500px;

  &.view {
    min-width: 8.8rem;
    max-width: 44.81rem;
  }

  &.edit {
    min-width: 5px;
    max-width: 44.81rem;
  }
`;
