import { useQueryClient } from "@tanstack/react-query";
import type { Ref } from "react";
import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "@/application/ui/lib/button/Button.tsx";
import { IconButton } from "@/application/ui/lib/button/IconButton.tsx";
import { Card } from "@/application/ui/lib/Card.tsx";
import { ReactComponent as XCloseIcon } from "@/assets/x-close.svg";

import { LoadingSpinner } from "../../../lib/spinner/LoadingSpinner";
import { ToastService } from "../../../lib/toast/ToastService";
import { theme } from "../../../theme/theme";
import { useCloseAndSubmitWritingTask } from "../hooks/useCloseAndSubmitWritingTask";
import { useCloseWritingTask } from "../hooks/useCloseWritingTask";
import { useTaskClosingConcernedStudents } from "../hooks/useTaskClosingConcernedStudents";
import { TeacherQueryKeys } from "../utils/TeacherQueryKeys";
import { CloseWritingTaskDialogContent } from "./CloseWritingTaskDialogContent";
import { CloseWritingTaskStudentsTable } from "./CloseWritingTaskStudentsTable";

export interface Props {
  icon: JSX.Element;
  title: string;
  confirmLabel: string;
  cancelLabel: string;
}

export interface CloseWritingTaskConfirmationDialogRef {
  display: (taskId: string) => void;
}

export const CloseWritingTaskConfirmationDialog = React.forwardRef(
  (
    { title, cancelLabel, icon }: Props,
    ref: Ref<CloseWritingTaskConfirmationDialogRef>,
  ) => {
    const [open, isOpen] = useState<boolean>(false);
    const [writingTaskId, setWritingTaskId] = useState<string | undefined>(
      undefined,
    );
    const { students, isLoading } =
      useTaskClosingConcernedStudents(writingTaskId);
    const queryClient = useQueryClient();

    const { closeWritingTask } = useCloseWritingTask({
      onSuccess: async () => {
        await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
        ToastService.showSuccess(
          t("closeWritingTask.success.title"),
          t("closeWritingTask.success.message"),
        );
      },
      onError: () => {
        ToastService.showError(t("closeWritingTask.fail"));
      },
    });

    const { closeAndSubmitWritingTask } = useCloseAndSubmitWritingTask({
      onSuccess: async () => {
        await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
        ToastService.showSuccess(
          t("closeAndSubmitWritingTask.success.title"),
          t("closeAndSubmitWritingTask.success.message"),
        );
      },
      onError: () => {
        ToastService.showError(t("closeAndSubmitWritingTask.fail"));
      },
    });

    useImperativeHandle(ref, () => ({
      display: (taskId: string) => {
        setWritingTaskId(taskId);
        isOpen(true);
      },
    }));

    const handleConfirmClose = () => {
      closeWritingTask(writingTaskId!);
      isOpen(false);
    };

    const handleConfirmSubmitAndClose = () => {
      closeAndSubmitWritingTask(writingTaskId!);
      isOpen(false);
    };

    const handleCancel = () => {
      isOpen(false);
    };

    const { t } = useTranslation();

    return (
      <Backdrop open={open}>
        <Modal as="dialog">
          <FloatingIconButton
            onClick={handleCancel}
            type="button"
            size="sm"
            hierarchy="neutral"
            variant="text"
          >
            <XCloseIcon />
          </FloatingIconButton>
          {icon}
          <ScrollableContainer>
            <Content>
              <Title>{title}</Title>
              <CloseWritingTaskDialogContent />
            </Content>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <CloseWritingTaskStudentsTable students={students ?? []} />
            )}
            <Actions>
              <ActionsContent>
                <ConfirmActions>
                  <Button
                    onClick={handleConfirmSubmitAndClose}
                    hierarchy="secondary"
                  >
                    {t(
                      "closeWritingTaskConfirmationDialog.actions.closeAndSubmit",
                    )}
                  </Button>
                  <Button onClick={handleConfirmClose} hierarchy="secondary">
                    {t("closeWritingTaskConfirmationDialog.actions.close")}
                  </Button>
                </ConfirmActions>
                <Button
                  onClick={handleCancel}
                  hierarchy="neutral"
                  variant="text"
                >
                  {cancelLabel}
                </Button>
              </ActionsContent>
            </Actions>
          </ScrollableContainer>
        </Modal>
      </Backdrop>
    );
  },
);

const ScrollableContainer = styled.div`
  display: grid;
  overflow-y: auto;
`;

const Backdrop = styled.div<{ open: boolean }>`
  ${({ open }) => `
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: ${open ? "flex" : "none"};
      justify-content: center;
      align-items: center;
      background-color: ${theme.colors.gray[700]}B3;
      backdrop-filter: blur(5px);
    `}
`;

const Modal = styled(Card)`
  flex-direction: column;
  z-index: 2;
  width: 474px;
  max-height: 80vh;
  padding: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.span`
  ${theme.typography.lg.semibold}
`;

const Actions = styled.div`
  padding-top: 32px;
`;

const ActionsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`;

const ConfirmActions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

const FloatingIconButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto;
`;
