import { useMutation, useQueryClient } from "@tanstack/react-query";

import { SectionsClient } from "@/application/client/SectionsClient.ts";
import { TeacherQueryKeys } from "@/application/ui/pages/Dashboard/utils/TeacherQueryKeys.ts";

interface RenameSection {
  sectionId: string;
  name: string;
}

export const useRenameSection = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: rename } = useMutation({
    mutationFn: ({ name, sectionId }: RenameSection) =>
      SectionsClient.renameSection(sectionId, name),

    onSuccess: async () => {
      await queryClient.invalidateQueries(TeacherQueryKeys.dashboard);
      await queryClient.invalidateQueries(TeacherQueryKeys.sections);
    },
  });

  return { rename };
};
