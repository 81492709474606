import { useRef } from "react";
import styled from "styled-components";

import type {
  AssignmentStatus,
  TeacherComment,
} from "@/application/domain/Assignment.ts";
import { TextCorrector } from "@/application/ui/pages/Revision/components/TextCorrector/TextCorrector.tsx";
import ToggleView from "@/application/ui/pages/Revision/components/ToggleView/ToggleView.tsx";
import { useToggleView } from "@/application/ui/pages/Revision/components/ToggleView/useToggleView.tsx";
import type { RevisedAssignmentContentState } from "@/application/ui/pages/Revision/service/RevisedAssignmentContentState.ts";
import { scrollbarStyle } from "@/application/ui/theme/Scrollbar.ts";

import { useReadOnlyUser } from "../../context/useReadOnlyUser";

export interface ConsultationAssignmentSectionProps {
  revisedAssignmentContentState: RevisedAssignmentContentState;
  revisedAssignmentStatus?: AssignmentStatus;
  comments: TeacherComment[];
}

export const ConsultationAssignmentEditor = ({
  revisedAssignmentContentState,
  comments,
}: ConsultationAssignmentSectionProps) => {
  const { currentView, toggleCurrentView } = useToggleView();
  const { readOnlyUser } = useReadOnlyUser();
  const textContainerRef = useRef<HTMLDivElement>(null);
  const onToggleCurrentView = () => {
    if (textContainerRef.current) {
      toggleCurrentView();
    }
  };

  return (
    <>
      <Header>
        <ToggleView toggleMode={currentView} onToggle={onToggleCurrentView} />
      </Header>

      {currentView === "original" && (
        <TextContainer ref={textContainerRef}>
          <TextCorrector
            data-testid="text-corrector"
            revisedAssignmentContentState={revisedAssignmentContentState}
            comments={comments}
            onContentStateChange={() => {}}
            onCommentsChange={() => {}}
            readOnly={readOnlyUser}
          />
        </TextContainer>
      )}

      {currentView === "revised" && (
        <TextContainer ref={textContainerRef} data-testid="revised-version">
          {revisedAssignmentContentState.toRevisedString()}
        </TextContainer>
      )}
    </>
  );
};

const TextContainer = styled.div`
  line-height: 36px;
  overflow-x: visible;
  overflow-y: auto;
  margin-top: 10px;
  padding-top: 24px;
  padding-bottom: 48px;
  white-space: pre-line;
  ${(props) => scrollbarStyle(props.theme)}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-around;
`;
