import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as WriteIcon } from "@/assets/write-icon.svg";

import type { StudentWritingTask } from "../../../../../domain/StudentDashboard";
import { Button } from "../../../../lib/button/Button";

export const COMPOSITION_PAGE_URL = "/etudiant/composition";
export const ASSIGNMENT_PAGE_URL = "/etudiant/travail";

interface Props {
  writingTask: StudentWritingTask;
}

export const StudentWritingTaskCardActions = ({ writingTask }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {["writing_in_progress", "todo"].includes(writingTask.status) && (
        <Button
          hierarchy="primary"
          disabled={!writingTask.isOpen}
          onClick={() => navigate(`${COMPOSITION_PAGE_URL}/${writingTask.id}`)}
        >
          <WriteIcon />
          {t("write")}
        </Button>
      )}

      {writingTask.assignmentId &&
        ["reviewed", "wait_for_revision"].includes(writingTask.status) && (
          <Button
            hierarchy="neutral"
            disabled={writingTask.status === "wait_for_revision"}
            onClick={() =>
              navigate(`${ASSIGNMENT_PAGE_URL}/${writingTask.assignmentId}`)
            }
          >
            {t("viewCopy")}
          </Button>
        )}
    </>
  );
};
