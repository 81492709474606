import { AllGradingScales } from "@emilia/backend/src/common/grade/model/GradingScaleType";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { TaskType } from "@/application/domain/TaskType.ts";
import { AllTaskTypes } from "@/application/domain/TaskType.ts";
import type { WritingTask } from "@/application/domain/writingTask.ts";
import { AllRedactionTypes } from "@/application/domain/WritingTask/RedactionType.ts";
import { Dropdown } from "@/application/ui/lib/inputs/Dropdown.tsx";
import { Input } from "@/application/ui/lib/inputs/Input.tsx";
import { NumberInput } from "@/application/ui/lib/inputs/NumberInput";
import { TextArea } from "@/application/ui/lib/inputs/TextArea.tsx";
import { WritingTaskCreationPanelCriteriaSelection } from "@/application/ui/pages/Dashboard/components/WritingTaskCreationPanelCriteriaSelection.tsx";
import { WritingTaskCreationPanelSelectedGroup } from "@/application/ui/pages/Dashboard/components/WritingTaskCreationPanelSelectedGroup.tsx";
import { useCriteriaStatus } from "@/application/ui/pages/Dashboard/hooks/useCriteriaStatus.ts";
import type { HomeroomState } from "@/application/ui/pages/Dashboard/hooks/useSelectedGroup.ts";
import { useSelectedGroup } from "@/application/ui/pages/Dashboard/hooks/useSelectedGroup.ts";

import type { Section } from "../../../../../domain/Teacher/TeacherDashboard";
import { theme } from "../../../../theme/theme";
import { AdvertiseSecuredMode } from "./AdvertiseSecuredMode";

interface Props {
  writingTask?: WritingTask;
  homerooms?: HomeroomState[];
  sections: (Section & { selected: boolean })[];
  defaultSectionId?: string;
  draft?: boolean;
}

export const WritingTaskContent = ({
  writingTask,
  sections,
  defaultSectionId,
  homerooms,
  draft = false,
}: Props) => {
  const { t } = useTranslation();
  const { getCriteria, toggleCriteria } = useCriteriaStatus(
    writingTask?.evaluatedCriteria,
  );
  const [taskType, setTaskType] = useState(
    writingTask?.taskType ?? AllTaskTypes[0],
  );
  const { homeroomStates, toggleSelectedState } = useSelectedGroup(
    homerooms ?? [],
  );

  const isUpdate = writingTask !== undefined && writingTask !== null && !draft;

  return (
    <>
      {homerooms && (
        <WritingTaskCreationPanelSelectedGroup
          homeroomsStates={homeroomStates}
          onToggleState={toggleSelectedState}
        />
      )}
      {sections && (
        <Dropdown
          options={sections.map((section) => ({
            label: section.name,
            value: section.id,
          }))}
          name="section"
          defaultValue={
            sections.find((x) => x.selected)?.id ?? defaultSectionId
          }
          disabled={isUpdate}
          label={t("sectionInput")}
        />
      )}
      <TaskTypeContainer>
        <AdvertiseSecuredMode color="success" />
        <SubContainer>
          <TaskDropdown
            options={AllTaskTypes.map((type) => ({
              label: t(`taskType.${type}`),
              value: type,
            }))}
            name="taskType"
            defaultValue={taskType}
            disabled={isUpdate}
            onChange={(value) => setTaskType(value as TaskType)}
            label={t("taskTypeInput")}
          />

          <NumberOfWordsInput>
            <NumberInput
              name="numberOfWords"
              label={t("numberOfWords")}
              defaultValue={writingTask?.numberOfWords?.toString()}
              placeholder={writingTask?.numberOfWords?.toString() ?? ""}
              disabled={isUpdate}
              required={false}
            />
          </NumberOfWordsInput>
        </SubContainer>
        {taskType === "summative" && (
          <SummativeDisclaimer>{t("summativeDisclaimer")}</SummativeDisclaimer>
        )}
      </TaskTypeContainer>

      <Dropdown
        options={AllRedactionTypes.map((type) => ({
          label: t(`redactionType.${type}`),
          value: type,
        }))}
        name="redactionType"
        defaultValue={writingTask?.redactionType ?? AllRedactionTypes[0]}
        disabled={isUpdate}
        label={t("redactionTypeInput")}
      />

      <Dropdown
        options={AllGradingScales.map((type) => ({
          label: t(`gradingScaleOption.${type}`),
          value: type,
        }))}
        name="gradingScaleType"
        defaultValue={writingTask?.gradingScaleType ?? AllGradingScales[0]}
        disabled={isUpdate}
        label={t("gradingScaleInput")}
      />

      <Input
        name="title"
        label={t("taskTitle")}
        defaultValue={writingTask?.name}
        placeholder={writingTask?.name}
        disabled={isUpdate}
        required={true}
      />

      <TextArea
        name="instructions"
        label={t("taskInstructions")}
        defaultValue={writingTask?.instructions}
        placeholder={
          writingTask?.instructions ?? t("taskInstructionsPlaceholder")
        }
        disabled={isUpdate}
        required={true}
      />

      <WritingTaskCreationPanelCriteriaSelection
        getCriteria={getCriteria}
        onChange={toggleCriteria}
      />
    </>
  );
};

const SummativeDisclaimer = styled.div`
  ${theme.typography.xs.regular};
`;

const TaskTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
`;

const TaskDropdown = styled(Dropdown)`
  flex: 6;
`;

const NumberOfWordsInput = styled.div`
  flex: 4;
`;
