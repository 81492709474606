import { t } from "i18next";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as TrashIcon } from "@/assets/trash.svg";

import type { TeacherWritingTask } from "../../../../../../../../domain/Teacher/TeacherDashboard";
import { Button } from "../../../../../../../lib/button/Button";
import { BaseModal } from "../../../../../../../lib/form/BaseModal";
import { FeaturedIcon } from "../../../../../../../lib/icon/FeaturedIcon";
import { LoadingSpinner } from "../../../../../../../lib/spinner/LoadingSpinner";
import { theme } from "../../../../../../../theme/theme";
import { useDeleteDraftWritingTask } from "../../../../../hooks/useDeleteDraftWritingTask";
import { useLinkedDraftWritingTask } from "../../../../../hooks/useLinkedDraftWritingTasks";
import type { WritingTaskContextMenuAction } from "../../../../WritingTaskContextMenuActions";

export const Delete = (
  onDeleteDraft: () => void,
): WritingTaskContextMenuAction => {
  const { t } = useTranslation();
  return {
    label: t("draftWritingTask.menuActions.delete"),
    children: (
      <>
        <DeleteIcon />
        <DeleteContainer>
          {t("draftWritingTask.menuActions.delete")}
        </DeleteContainer>
      </>
    ),
    callback: onDeleteDraft,
  };
};

type ConfirmationDialogProps = {
  writingTask: TeacherWritingTask;
  open: boolean;
  isOpen: (_val: boolean) => void;
};

export const DeleteConfirmationModal = ({
  isOpen,
  open,
  writingTask,
}: ConfirmationDialogProps) => {
  const { deleteDraft } = useDeleteDraftWritingTask();
  const { linkedTasks, isLoading } = useLinkedDraftWritingTask(
    writingTask.id,
    open,
  );
  return (
    <BaseModal
      open={open}
      icon={
        <FeaturedIcon color="error" size={20}>
          <TrashIcon />
        </FeaturedIcon>
      }
      onClose={() => isOpen(false)}
      title={t("draftWritingTask.deletionModal.title")}
      description={
        <>
          <span>{t("draftWritingTask.deletionModal.description")}</span>
          <LinkedGroupList>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              linkedTasks?.groups.map((x, index) => (
                <LinkedGroup key={index}>{x}</LinkedGroup>
              ))
            )}
          </LinkedGroupList>
          <span>{t("draftWritingTask.deletionModal.confirmation")}</span>
        </>
      }
    >
      <DeleteButton
        hierarchy="neutral"
        onClick={() => {
          isOpen(false);
        }}
      >
        {t("cancel")}
      </DeleteButton>
      <DeleteButton
        hierarchy="error"
        variant="contained"
        onClick={() => {
          isOpen(false);
          deleteDraft(writingTask.id);
        }}
      >
        {t("draftWritingTask.deletionModal.delete")}
      </DeleteButton>
    </BaseModal>
  );
};

const LinkedGroupList = styled.div`
  display: flex;
  gap: 16px;
`;

const LinkedGroup = styled.div`
  padding: 2px 8px;
  border-radius: ${theme.radius.xlarge};
  background-color: ${theme.colors.gray[100]};
  ${theme.typography.sm.medium};
`;

const DeleteIcon = styled(TrashIcon)`
  * {
    stroke: ${theme.colors.error[500]};
  }
`;

const DeleteButton = styled(Button)`
  width: auto;
`;

const DeleteContainer = styled.div`
  color: ${theme.colors.error[500]};
`;
