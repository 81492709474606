import { useMemo, useState } from "react";

import { AssignmentStatus } from "../../../../domain/Assignment";
import type { WritingTaskStudentSummary } from "../../../../domain/WritingTaskSummary";

export enum SortType {
  name,
  grades,
  status,
  published,
}

const statusOrder: Record<AssignmentStatus, number> = {
  [AssignmentStatus.NOT_SUBMITTED]: 0,
  [AssignmentStatus.WRITING_IN_PROGRESS]: 1,
  [AssignmentStatus.CORRECTED]: 2,
  [AssignmentStatus.CORRECTION_REJECTED]: 3,
  [AssignmentStatus.WAIT_FOR_CORRECTION]: 4,
  [AssignmentStatus.GRADING]: 5,
  [AssignmentStatus.GRADED]: 6,
  [AssignmentStatus.NULL]: 7,
};

export enum SortDirection {
  asc,
  desc,
}

export const useSummarySort = (groupSummary: WritingTaskStudentSummary[]) => {
  const [activeSort, setActiveSort] = useState<SortType>(SortType.name);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.desc,
  );
  const sortedSummaries = useMemo(() => {
    const sorted = [...groupSummary];

    switch (activeSort) {
      case SortType.name:
        sorted.sort(byName);
        break;
      case SortType.grades:
        sorted.sort(byGrade);
        break;
      case SortType.published:
        sorted.sort(byPublished);
        break;
      case SortType.status:
        sorted.sort(byStatus);
        break;
      default:
        break;
    }

    return sortDirection === SortDirection.desc ? sorted : sorted.reverse();
  }, [groupSummary, activeSort, sortDirection]);

  const handleSort = (newSort: SortType) => {
    if (activeSort === newSort) {
      setSortDirection((prevDirection) =>
        prevDirection === SortDirection.asc
          ? SortDirection.desc
          : SortDirection.asc,
      );
    } else {
      setActiveSort(newSort);
      setSortDirection(SortDirection.desc);
    }
  };

  return {
    activeSort,
    sortDirection,
    handleSort,
    sortedSummaries,
  };
};

export const byName = (
  a: WritingTaskStudentSummary,
  b: WritingTaskStudentSummary,
) => {
  return a.name.lastName
    .toLocaleLowerCase()
    .localeCompare(b.name.lastName.toLocaleLowerCase());
};

export const byStatus = (
  a: WritingTaskStudentSummary,
  b: WritingTaskStudentSummary,
) => {
  const aStatus = a.assignment?.status;
  const bStatus = b.assignment?.status;

  if (aStatus === undefined || bStatus === undefined) {
    throw new Error("Assignment status should not be undefined");
  }

  return statusOrder[aStatus] - statusOrder[bStatus];
};

export const byPublished = (
  a: WritingTaskStudentSummary,
  b: WritingTaskStudentSummary,
) => {
  if (a.assignment?.published === b.assignment?.published) {
    return 0;
  } else if (a.assignment?.published) {
    return -1;
  } else {
    return 1;
  }
};

export const byGrade = (
  a: WritingTaskStudentSummary,
  b: WritingTaskStudentSummary,
) => {
  const gradeA: number =
    GradeValueMapping.find((x) => x.letter === a.assignment?.grade)?.value || 0;
  const gradeB: number =
    GradeValueMapping.find((x) => x.letter === b.assignment?.grade)?.value || 0;

  return gradeB - gradeA;
};

export type LetterWithVariantGrade =
  | "A+"
  | "A++"
  | "A"
  | "A-"
  | "A--"
  | "B++"
  | "B+"
  | "B"
  | "B-"
  | "B--"
  | "C++"
  | "C+"
  | "C"
  | "C-"
  | "C--"
  | "D++"
  | "D+"
  | "D"
  | "D-"
  | "D--"
  | "E++"
  | "E+"
  | "E"
  | "E-"
  | "E--";

export type GradeWithValue = { letter: LetterWithVariantGrade; value: number };

const letterFromVariantGrade = (index: number): LetterWithVariantGrade => {
  const variants: LetterWithVariantGrade[] = [
    "A++",
    "A+",
    "A",
    "A-",
    "A--",
    "B++",
    "B+",
    "B",
    "B-",
    "B--",
    "C++",
    "C+",
    "C",
    "C-",
    "C--",
    "D++",
    "D+",
    "D",
    "D-",
    "D--",
    "E++",
    "E+",
    "E",
    "E-",
    "E--",
  ];
  return variants[index % variants.length];
};

export const GradeValueMapping: GradeWithValue[] = Array.from(
  { length: 25 },
  (_, i) => ({
    letter: letterFromVariantGrade(i),
    value: 25 - i,
  }),
);
