import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "@/application/ui/theme/theme";
import { ReactComponent as OpenIcon } from "@/assets/chevron-down.svg";
import { ReactComponent as CloseIcon } from "@/assets/chevron-up.svg";
import { ReactComponent as EditIcon } from "@/assets/edit.svg";

import type { TeacherSection } from "../../../../../domain/Teacher/TeacherDashboard";
import { useRenameSection } from "../../hooks/useRenameSection";
import { SectionContextMenu } from "./SectionContextMenu";
import { SectionHeaderEdition } from "./SectionHeaderEdition";

export type SectionHeaderProps = {
  section: TeacherSection;
  hideActions?: boolean;
};

export const SectionHeader = ({
  section,
  hideActions,
  open,
}: SectionHeaderProps & { open: boolean }) => {
  const { rename } = useRenameSection();
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation();

  const onRename = (newName: string) => {
    void rename({ sectionId: section.id, name: newName }).then(() =>
      setEditing(false),
    );
  };

  const renameAction = {
    label: t("sections.rename"),
    children: (
      <>
        <EditIcon />
        {t("sections.rename")}
      </>
    ),
    callback: () => setEditing(true),
  };

  return (
    <HeaderContainer open={open}>
      {editing ? (
        <SectionHeaderEdition
          name={section.name}
          onCancel={() => setEditing(false)}
          onSubmit={onRename}
        />
      ) : (
        <>
          <Label>{section.name}</Label>
          <IconsContainer>
            {open ? <CloseIcon /> : <OpenIcon />}
            {!hideActions && <SectionContextMenu actions={[renameAction]} />}
          </IconsContainer>
        </>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.gray[300]};
  padding-bottom: 16px;
  width: 100%;
  ${(props) => props.open && "margin-bottom: 32px"};
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Label = styled.label`
  ${theme.typography.sm.medium};
`;
