import type { DraftWTLinkedGroupsResponseDto } from "@api/DraftWTLinkedGroupsResponse";
import type { WritingTaskResponseDto } from "@api/WritingTaskResponse.dto.ts";

import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";
import { WritingTaskMapper } from "@/application/client/mapper/WritingTaskMapper.ts";
import type { WritingTask } from "@/application/domain/writingTask.ts";

import type { DraftWTLinkedGroups } from "../domain/DraftWTLinkedGroups";

export class WritingTaskClient {
  static getById = async (writingTaskId: string): Promise<WritingTask> => {
    const { data } = await AuthenticatedHttpClient.get<WritingTaskResponseDto>(
      `/writing-tasks/${writingTaskId}`,
    );

    return WritingTaskMapper.assembleToDomain(data);
  };

  static getDraftById = async (writingTaskId: string): Promise<WritingTask> => {
    const { data } = await AuthenticatedHttpClient.get<WritingTaskResponseDto>(
      `/writing-tasks/${writingTaskId}/draft`,
    );

    return WritingTaskMapper.assembleToDomain(data);
  };

  static getLinkedGroups = async (
    writingTaskId: string,
  ): Promise<DraftWTLinkedGroups> => {
    const { data } =
      await AuthenticatedHttpClient.get<DraftWTLinkedGroupsResponseDto>(
        `/writing-tasks/${writingTaskId}/draftGroupLinks`,
      );

    return data;
  };

  static archiveTask = async (writingTaskId: string): Promise<void> => {
    await AuthenticatedHttpClient.post(
      `/writing-tasks/${writingTaskId}/archive/`,
    );
  };

  static deleteDraftTask = async (writingTaskId: string): Promise<void> => {
    await AuthenticatedHttpClient.post(
      `/writing-tasks/${writingTaskId}/deleteDraft`,
    );
  };

  static archiveUndoTask = async (writingTaskId: string): Promise<void> => {
    await AuthenticatedHttpClient.post(
      `/writing-tasks/${writingTaskId}/undoArchive/`,
    );
  };
}
