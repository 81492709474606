export const ellipsisStyle = ({
  numberOfRows,
  lineHeightInPixels,
}: {
  numberOfRows: number;
  lineHeightInPixels: number;
}) => `
  display: -webkit-box;
  -webkit-line-clamp: ${numberOfRows};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: ${numberOfRows * lineHeightInPixels}px;
  max-height: ${numberOfRows * lineHeightInPixels}px;
  line-height: ${lineHeightInPixels}px;
  word-break: break-word;
  word-wrap: break-word;
`;
