import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { BoldTrans } from "../../../../../i18n/BoldTrans";
import { theme } from "../../../theme/theme";

export interface Props {
  icon: JSX.Element;
  title: string;
  confirmLabel: string;
  cancelLabel: string;
  taskId: string;
}

export const CloseWritingTaskDialogContent = () => {
  const { t } = useTranslation();

  return (
    <Content>
      <Text>{t("closeWritingTaskConfirmationDialog.content.choices")}</Text>
      <List>
        <Text>
          <BoldTrans translationKey="closeWritingTaskConfirmationDialog.content.closeAndSubmit" />
        </Text>
        <Text>
          <BoldTrans translationKey="closeWritingTaskConfirmationDialog.content.close" />
        </Text>
      </List>
    </Content>
  );
};

const Content = styled.div`
  color: ${theme.colors.gray[600]};
`;

const Text = styled.span`
  ${theme.typography.sm.regular};
`;

const List = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
