import clsx from "clsx";
import { Popover } from "react-tiny-popover";
import styled from "styled-components";

import type { TeacherComment } from "@/application/domain/Assignment";
import type { CorrectionMode } from "@/application/ui/pages/Revision/RevisionStates/AssignmentsProvider.tsx";
import { PositionCalculator } from "@/application/ui/pages/Revision/service/PositionCalculator.ts";

import { CommentPopup } from "../../RevisionPopup/CommentPopup";
import { useRevisionPopup } from "../../RevisionPopup/useRevisionPopup";

interface Props {
  readOnly: boolean;
  comment: TeacherComment;
  onDelete: () => void;
  onChange: (comment: TeacherComment) => void;
  parent: HTMLDivElement;
  parentCoordinates: DOMRect;
  mode: CorrectionMode;
}

export const HighlightedComment = ({
  readOnly,
  comment,
  onDelete,
  onChange,
  parent,
  parentCoordinates,
  mode,
}: Props) => {
  const validateEmptyComment = () => {
    if (comment.text.trim() === "") onDelete();
  };
  const { popupIsVisible, showPopupInViewMode, closePopup } = useRevisionPopup({
    popupIsVisibleBaseValue: comment.text.trim() === "",
    afterClosingAction: validateEmptyComment,
  });

  const isRectEncapsulated = (innerRect: DOMRect, outerRect: DOMRect) => {
    return (
      innerRect.x >= outerRect.x &&
      innerRect.y >= outerRect.y &&
      innerRect.x + innerRect.width <= outerRect.x + outerRect.width &&
      innerRect.y + innerRect.height <= outerRect.y + outerRect.height
    );
  };

  const getRects = (
    container: Node,
    startOffset: number,
    endOffset: number,
  ): DOMRect[] => {
    const { startNode, startNodeOffset, endNode, endNodeOffset } =
      PositionCalculator.calculateNodeRange(startOffset, endOffset, container);
    try {
      if (
        !startNode ||
        !endNode ||
        startNodeOffset === undefined ||
        endNodeOffset === undefined
      ) {
        console.warn("Could not locate start and end nodes within the text.");
        return [];
      }

      const range = document.createRange();
      range.setStart(startNode, startNodeOffset);
      range.setEnd(endNode, endNodeOffset);

      const rects = range.getClientRects();
      range.detach();

      const rectList: DOMRect[] = [];
      if (rects.length) {
        for (const rect of rects) {
          rectList.push(rect);
        }
      }

      const filteredRectList: DOMRect[] = [];
      rectList.forEach((rect) => {
        let shouldKeep = true;

        for (const [index, otherRect] of filteredRectList.entries()) {
          if (isRectEncapsulated(rect, otherRect)) {
            shouldKeep = false;
            break;
          } else if (isRectEncapsulated(otherRect, rect)) {
            filteredRectList.splice(index, 1);
          }
        }

        if (shouldKeep) {
          filteredRectList.push(rect);
        }
      });

      return filteredRectList;
    } catch (exception) {
      return [];
    }
  };

  return (
    <Popover
      isOpen={popupIsVisible}
      positions={["right", "left", "top"]}
      padding={10}
      content={
        <CommentPopup
          readOnly={readOnly}
          comment={comment}
          onDelete={onDelete}
          onChange={onChange}
          onClose={closePopup}
        />
      }
    >
      <HighlightContainer
        data-testid={`comment_${comment.id}`}
        onClick={showPopupInViewMode}
        className={clsx({ isCommentMode: mode === "comment" })}
      >
        {getRects(parent, comment.startIndex, comment.endIndex).map((r, i) => (
          <Highlight
            key={`${comment.id}_${i}`}
            x={r.left - parentCoordinates.left}
            y={r.top - parentCoordinates.top}
            width={r.width}
            height={r.height}
            className={clsx({
              isSelected: popupIsVisible,
              isCommentMode: mode === "comment",
            })}
          ></Highlight>
        ))}
      </HighlightContainer>
    </Popover>
  );
};

const Highlight = styled.rect`
  fill: rgba(127, 86, 217, 0.1);

  &.isCommentMode {
    fill: rgba(127, 86, 217, 0.2);
  }

  &.isSelected {
    fill: rgba(127, 86, 217, 0.4);
  }
`;

const HighlightContainer = styled.g`
  &.isCommentMode {
    pointer-events: auto;
    cursor: pointer;
  }
`;
