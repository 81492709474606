import type { TeacherDashboardResponse } from "@api/TeacherDashboardResponse.ts";

import { AuthenticatedHttpClient } from "@/application/client/HttpClient.ts";
import type { TeacherDashboard } from "@/application/domain/Teacher/TeacherDashboard.ts";
import { Criterias } from "@/application/ui/pages/Dashboard/hooks/useCriteriaStatus.ts";

export const TeacherDashboardClient = {
  fetchHomerooms: async (
    homeroomId: string,
    includeMasked: boolean,
  ): Promise<TeacherDashboard> => {
    const { data } =
      await AuthenticatedHttpClient.get<TeacherDashboardResponse>(
        `/teacher/dashboard/${homeroomId}/`,
        { timeout: 25000, params: { includeMasked } },
      );
    return data;
  },

  createWritingTask: async (
    homeroomIds: string[],
    formData: FormData,
  ): Promise<void> => {
    await AuthenticatedHttpClient.post(`/writing-tasks`, {
      homeroomIds,
      ...buildFormPayload(formData),
    });
  },

  updateWritingTask: async (
    homeroomId: string,
    writingTaskId: string,
    formData: FormData,
  ): Promise<void> => {
    await AuthenticatedHttpClient.put(`/writing-tasks/${writingTaskId}`, {
      homeroomId,
      ...buildFormPayload(formData),
    });
  },

  updateDraftWritingTask: async (
    homeroomId: string,
    writingTaskId: string,
    formData: FormData,
  ): Promise<void> => {
    await AuthenticatedHttpClient.put(`/writing-tasks/${writingTaskId}/draft`, {
      homeroomId,
      ...buildFormPayload(formData),
    });
  },

  publishWritingTask: async (writingTaskId: string): Promise<void> => {
    await AuthenticatedHttpClient.post<void>(
      `/writing-tasks/${writingTaskId}/publish`,
    );
  },

  publishAnAssignment: async (
    writingTaskId: string,
    assignmentId: string,
  ): Promise<void> => {
    await AuthenticatedHttpClient.post<void>(
      `/writing-tasks/${writingTaskId}/publish/${assignmentId}`,
    );
  },
};

function buildEvaluationCriteria(formData: FormData) {
  return {
    C1: formData.get(Criterias[Criterias.C1]) === "on",
    C2: formData.get(Criterias[Criterias.C2]) === "on",
    C3: formData.get(Criterias[Criterias.C3]) === "on",
    C4: formData.get(Criterias[Criterias.C4]) === "on",
    C5: formData.get(Criterias[Criterias.C5]) === "on",
  };
}

function buildFormPayload(formData: FormData) {
  return {
    title: formData.get("title"),
    instructions: formData.get("instructions"),
    redactionType: formData.get("redactionType"),
    taskType: formData.get("taskType"),
    gradingScaleType: formData.get("gradingScaleType"),
    sectionId: formData.get("section"),
    evaluationCriteria: buildEvaluationCriteria(formData),
    numberOfWords:
      formData.get("numberOfWords") !== ""
        ? formData.get("numberOfWords")
        : undefined,
  };
}
