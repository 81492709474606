import { AccordionItem as Item } from "@szhsin/react-accordion";
import { t } from "i18next";
import styled from "styled-components";

import type { WritingTaskListing } from "../../../../../domain/HomeroomListing";
import { NoWritingTasks } from "../NoWritingTasks";
import { WritingTaskCard } from "../TeacherWritingTaskCard/WritingTaskCard";
import type { SectionHeaderProps } from "./SectionHeader";
import { SectionHeader } from "./SectionHeader";

export type DashboardSectionProps = {
  openCreationFor: () => void;
  openUpdateFor: (writingTaskId: string) => void;
  openUpdateForDraft: (writingTaskId: string) => void;
  onOpenGradePanel: (task: WritingTaskListing) => void;
  allowDirectCreation?: boolean;
  homeroomId: string;
  initialEntered?: boolean;
  hideActions?: boolean;
} & SectionHeaderProps;

export const DashboardSection = ({
  allowDirectCreation,
  openCreationFor,
  openUpdateFor,
  openUpdateForDraft,
  onOpenGradePanel,
  section,
  homeroomId,
  initialEntered,
  hideActions,
  ...props
}: DashboardSectionProps) => {
  const hasTasks = section.tasks.length > 0;

  return (
    <AccordionItem
      initialEntered={initialEntered}
      buttonProps={{ style: { width: "100%" } }}
      onClick={(e) => {
        e.preventDefault();
      }}
      header={({ state }) => (
        <SectionHeader
          hideActions={hideActions}
          open={state.isEnter}
          {...props}
          section={section}
        />
      )}
    >
      {hasTasks ? (
        <WritingTasks aria-label={t("writingTaskListing")}>
          {section.tasks.map((task) => (
            <WritingTaskCard
              key={task.id}
              writingTask={task}
              onUpdateWritingTask={() => openUpdateFor(task.id)}
              onUpdateDraftWritingTask={() => openUpdateForDraft(task.id)}
              seeStudentsList={() => onOpenGradePanel(task)}
              homeroomId={homeroomId}
            />
          ))}
        </WritingTasks>
      ) : (
        allowDirectCreation && (
          <NoWritingTasks onCreateClick={openCreationFor} />
        )
      )}
    </AccordionItem>
  );
};

const AccordionItem = styled(Item)`
  margin-top: 32px;
`;

const WritingTasks = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  padding-top: 0;
`;
