import type { StudentWritingTask } from "../../../../../domain/StudentDashboard";
import { ClosedHeader } from "../TeacherWritingTaskCard/Header/ClosedHeader";
import { MenuRow } from "../TeacherWritingTaskCard/Header/HeaderFactory";
import { RevisedHeader } from "./Header/RevisedHeader";
import { SubmittedHeader } from "./Header/SubmittedHeader";
import { WIPHeader } from "./Header/WIPHeader";
import { StudentWritingTaskContextMenu } from "./StudentWritingTaskContextMenu";

export const StudentHeaderFactory = ({
  writingTask,
}: {
  writingTask: StudentWritingTask;
}) => {
  const status = writingTask.isOpen ? writingTask.status : "closed";
  switch (status) {
    case "reviewed":
      return (
        <MenuRow>
          <RevisedHeader />
          <StudentWritingTaskContextMenu writingTask={writingTask} />
        </MenuRow>
      );
    case "wait_for_revision":
      return (
        <MenuRow>
          <SubmittedHeader />
          <StudentWritingTaskContextMenu writingTask={writingTask} />
        </MenuRow>
      );
    case "writing_in_progress":
      return (
        <MenuRow>
          <WIPHeader />
          <StudentWritingTaskContextMenu writingTask={writingTask} />
        </MenuRow>
      );
    case "closed":
      return (
        <MenuRow>
          <ClosedHeader />
          <StudentWritingTaskContextMenu writingTask={writingTask} />
        </MenuRow>
      );
    case "todo":
      return (
        <MenuRow>
          <div></div>
          <StudentWritingTaskContextMenu writingTask={writingTask} />
        </MenuRow>
      );
    default:
      break;
  }
};
