import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { theme } from "@/application/ui/theme/theme.ts";
import { ReactComponent as FileIcon } from "@/assets/file-check.svg";

export const RevisedHeader = () => {
  const { t } = useTranslation();
  return (
    <Tag>
      <FileCheck />
      <span>{t("studentWritingTaskStatus.reviewed")}</span>
    </Tag>
  );
};

const FileCheck = styled(FileIcon)`
  width: 12px;
  height: 12px;
`;

const Tag = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: ${theme.colors.success[50]};
  border-radius: 16px;
  padding: 4px 12px;
  ${theme.typography.sm.medium};
  color: ${theme.colors.success[700]};
`;
