import { useTranslation } from "react-i18next";
import styled from "styled-components";

import type { CloseWritingTaskGroupSummary } from "../../../../domain/WritingTaskStatus/CloseWritingTaskGroupSummary";
import { theme } from "../../../theme/theme";

export interface Props {
  students: CloseWritingTaskGroupSummary;
}

export const CloseWritingTaskStudentsTable = ({ students }: Props) => {
  const { t } = useTranslation();

  return (
    <StudentsTable>
      <thead>
        <StudentsTableRow>
          <StudentsTableHeaderCell>{t("prenomNom")}</StudentsTableHeaderCell>
        </StudentsTableRow>
      </thead>
      <tbody>
        {students?.map((x, index) => (
          <StudentsTableRow key={index}>
            <StudentsTableBodyCell>{x.name.fullName()}</StudentsTableBodyCell>
          </StudentsTableRow>
        ))}
      </tbody>
    </StudentsTable>
  );
};

const StudentsTable = styled.table`
  margin-top: 16px;
`;

const StudentsTableRow = styled.tr`
  border-bottom: 1px solid ${theme.colors.gray[200]};
`;

const StudentsTableHeaderCell = styled.th`
  background-color: ${theme.colors.gray[50]};
  text-align: left;
  padding: 8px 16px;
  ${theme.typography.xs.regular};
`;

const StudentsTableBodyCell = styled.td`
  padding: 8px 16px;
  ${theme.typography.sm.medium};
`;
